import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'
import FormYesNo from '../../../views/custom-quote/components/FormYesNo'

import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'


const Has3DModel = () => {

  const { formData, updateFormData } = useContext(CustomQuoteContext)

  const handleSelect = (item) => {
    updateFormData({
      ...formData,
      has3DModel: item,
    })
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.has3DModel.next)
  }

  const handleSubmit = () => {
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.has3DModel.next)
  }

  return (
    <CustomQuoteLayout>
      <FormQuestion
        title="Do you have a 3D model ready to send us?"
        description="Don't worry if you don't have. We can build one for you."
      />
      <FormYesNo
        value={formData.has3DModel}
        onSelect={handleSelect}
      />
      <FormNavigation
        onSubmit={handleSubmit}
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.has3DModel.previous}
      />
    </CustomQuoteLayout>
  )
}

export default Has3DModel
