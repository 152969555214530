import React from 'react'
import { css } from 'styled-components'

import {
  space,
  palette,
  colors
} from '../../../utils/tokens'

const Card = ({ name, onSelect, isSelected }) => {
  return (
    <button
      onClick={onSelect}
      css={css({
        borderRadius: `4px`,
        cursor: `pointer`,
        padding: `${space[4]}px`,
        transition: `background-color 300ms, border 300ms`,
        '&:hover, &:focus': {
          backgroundColor: palette.orange[10],
          color: palette.orange[90],
          borderColor: `${palette.orange[20]}`,
        },
        backgroundColor: isSelected ? palette.orange[20] : colors.white,
        border: isSelected
          ? `2px solid ${colors.primaryBrand}`
          : `2px solid ${palette.grey[40]}`,
        color: isSelected ? palette.orange[90] : `initial`,
      })}
    >
      <span
        css={css({
          fontWeight: `bold`,
        })}
      >
        {name}
      </span>
    </button>
  )
}

const FormYesNo = ({
  value,
  onSelect,
}) => {
  return (
    <div
      css={css({
        display: `grid`,
        gridTemplateColumns: `1fr 1fr`,
        gridGap: `${space[3]}px`,
      })}
    >
      <Card
        name="Yes"
        isSelected={value === true}
        onSelect={() => onSelect(true)}
      />
      <Card
        name="No"
        isSelected={value === false}
        onSelect={() => onSelect(false)}
      />
    </div>
  )
}

export default FormYesNo